<template>
  <div class="p_b">
    <!-- 导航 -->
    <div class="td_head_nav" :style="colorState?'background-color:#3BD367;':''">
      <van-icon name="arrow-left" size="28" color="#ffffff" @click="revert"/>
      <div class="d_f ali_c j_s p_r">
        <img
          style="visibility: hidden"
          class="td_collect cur_p"
          @click="collect"
          :src="isCollect ? td_collect_full: td_collect_empty"
          alt
        >
        <img
          class="td_head_more cur_p"
          src="./img/td_headmore.png"
          @click="showHeadBox = !showHeadBox"
        >
        <div class="p_a td_head_box_out" v-if="showHeadBox">
          <img class="td_head_angle p_a" src="./img/td_head_angle.png">
          <div class="tdhb_item d_f ali_c bb cur_p" @click="$router.push('/')">
            <img src="./img/td_home.png">
            <span>回到首页</span>
          </div>
          <div class="tdhb_item d_f ali_c bb cur_p" @click="goIntro('/orderList',3)">
            <img src="./img/td_order.png">
            <span>我的订单</span>
          </div>
          <!--<div class="tdhb_item d_f ali_c cur_p" @click="$router.push('/collection')">
            <img src="./img/td_collect_empty.png">
            <span>我的收藏</span>
          </div>-->
        </div>
      </div>
    </div>
    <!-- 顶部 -->
    <div class="header">
      <van-swipe @change="onChange" :autoplay="3000">
        <van-swipe-item v-for="(item , index) in (comTick.attachments.length ? comTick.attachments : [{url:comTick.cover}])" :key="index">
          <img :src="item.url" alt style="object-fit: cover">
        </van-swipe-item>
        <template #indicator>
          <div class="custom-indicator">图片{{ current + 1 }}/{{comTick.attachments.length ? comTick.attachments.length : 1}}</div>
        </template>
      </van-swipe>
    </div>
    <div class="line"></div>
    <!--  -->
    <div class="info">
      <h1>{{comTick.name}}</h1>
      <ul class="tag" v-if="comTick.tags">
        <li v-for="(item,index) in comTick.tags.split(' ')" :key="index">{{item}}</li>
      </ul>
      <!-- 2022.3.6代提出 隐藏 -->
      <!-- <div class="nav">
        <div class="address">
          <h6>{{sellerInfo.name}}</h6>
          <p>{{sellerInfo.address}}</p>
        </div>
        <div class="navto" @click="goMap" v-if="sellerInfo.longitude && sellerInfo.latitude">导航</div>
      </div> -->
      <div class="notice" v-if="sellerInfo.notice" @click="goIntro('/notice')">
        <van-notice-bar
          left-icon="volume"
          mode="link"
          background="#FEF6F0"
          color="#FF4A4A"
          :scrollable="false"
        >
          <van-swipe vertical class="notice-swipe" :autoplay="3000" :show-indicators="false">
            <van-swipe-item>{{sellerInfo.notice}}</van-swipe-item>
          </van-swipe>
        </van-notice-bar>
      </div>
    </div>
    <div class="line"></div>
    <!-- -套餐说明- -->
    <div class="illustrate" v-if="comTick.remark">
      <p>确认购买之前请仔细阅读套餐说明</p>
      <div class="taocan">
        <h6>- 套餐说明 -</h6>
        <ul>
          <li v-for="item in comTick.remark.split('\n')">
            <span></span>
            <p>{{item}}</p>
          </li>
        </ul>
      </div>
      <div class="price">
        <span>￥{{comTick.sellPrice ? comTick.sellPrice/100 : 0}}</span>
        <span style="text-decoration: line-through">￥{{comTick.shopPrice ? comTick.shopPrice/100 : 0}}</span>
        <!--<span>节假日有加收</span>-->
      </div>
    </div>
    <div class="line"></div>
    <!-- 富文本 -->
    <div class="content">
      <h3>套餐详情</h3>
      <div v-html="comTick.depiction"></div>
    </div>

    <!-- 底部 -->
    <div class="bottom">
      <span class="kefu" @click="tel">客服咨询</span>
      <van-button class="btn" type="primary" @click="Reserve">点击预定</van-button>
    </div>
    <!--点击预定出现日期弹窗-->
    <van-action-sheet v-model:show="showBuyDate" >
      <div class="t_buy_date">
        <div class="head d_f ali_c">
          <div class="flex_1">{{comTick.name}}</div>
          <div class="close">
            <img class="full_w" src="./img/t_close.png" alt @click="closeBuyDate">
          </div>
        </div>
        <div class="red_tip">请在使用时间内前往景区</div>
        <template v-if="dateArr.length">
          <div class="tip">请选择使用门票日期</div>
          <div class="date_out d_f ali_c">
            <div class="over_out">
              <div class="flex_1 date_item_out">
                <template v-for="(item,index) in dateArr">
                  <div class="date_item dis"  v-if="item.dis">
                    <div class="d_f ali_c j_s">
                      <span>{{item.date}}</span>
                    </div>
                    <div class="price">不可订</div>
                    <div class="p_a tag">
                      <img class="full_w" src="./img/t_date_choose.png" alt>
                    </div>
                  </div>

                  <div class="date_item"  :id="'date'+index" v-else :class="chooseDate === index ? 'choose' : ''"  @click="dateClick(index,item)" >
                    <div class="d_f ali_c j_s">
                      <span>{{item.date}}</span>
                    </div>
                    <div class="price">￥{{item.sellPrice ? (item.sellPrice/100).toFixed(2) : 0}}</div>
                    <div class="p_a tag">
                      <img class="full_w" src="./img/t_date_choose.png" alt>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="date_more p_a d_f ali_c cur_p" @click="moreDate">
              <div class="flex_1">更多日期</div>
              <div class="date_more_icon">
                <img class="full_w" src="./img/td_right.png" alt>
              </div>
            </div>
          </div>
        </template>

        <!--<template v-if="timeArr.length">
          <div class="tip mt_12">请选择使用时段</div>
          <div class="time_out">
            <div class="over_out">
              <template v-for="(item,index) in timeArr">
                <div v-if="!item.dis" :id="'date'+index" class="time_item" :class="chooseTime === index ? 'choose' : ''" @click="timeClick(index,item)">
                  <span style="margin-right: 5px">{{item.timeSlot}}</span>
                  <span>{{'￥'+(item.sellPrice ? (item.sellPrice/100).toFixed(2):0)}}</span>
                  <div class="p_a tag">
                    <img class="full_w" src="./img/t_date_choose.png" alt="">
                  </div>
                </div>
                <div v-else class="time_item dis">
                  <span>{{item.timeSlot}}</span>
                  <span>{{'￥'+(item.sellPrice ? (item.sellPrice/100).toFixed(2):0)}}</span>
                  <div class="p_a tag">
                    <img class="full_w" src="./img/t_date_choose.png" alt="">
                  </div>
                </div>
              </template>
            </div>
          </div>
        </template>-->
        <div class="date_btn cur_p p_a" @click="goOrder">立即预订</div>
      </div>
    </van-action-sheet>
    <!-- 客服咨询 -->
    <van-popup v-model:show="showTel" closeable round >
      <div class="telPop">
         <span class="kefu">客服咨询</span>
         <p>如需详细了解产品细则，可拨打如下号码</p>
         <a :href="'tel:' + sellerInfo.telephone" rel="external nofollow" >{{sellerInfo.telephone}}</a>
      </div>
    </van-popup>
    <!-- 日历 -->

    <van-calendar
      title="选择使用日期"
      v-model:show="showDatePicker"
      color="#1ACC70"
      @confirm="confirmDate"
      @select="selectDate"
      :formatter="formatter" />
  </div>
</template>
<script>
import td_collect_empty from "./img/td_collect_empty.png";
import td_collect_full from "./img/td_collect_full.png";
// import loginPop from '../../../../../cmps/loginPop/loginPop'
var u = navigator.userAgent;
var isAndroid =
  u.indexOf("Android") > -1 || u.indexOf("Adr") > -1 || u.indexOf("Linux") > -1; //android终端
var isiOS =
  !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) ||
  u.indexOf("iPhone") > -1 ||
  u.indexOf("iPad") > -1 ||
  u.indexOf("iOS") > -1; //ios终端

Date.prototype.formatNoS = function() {
  var s = "";
  s += this.getFullYear() + "-"; // 获取年份。
  s += this.getMonth() + 1 + "-"; // 获取月份。
  s += this.getDate(); // 获取日。
  return s; // 返回日期。
};

//按日查询
function getDayAll(begin, end) {
  var dateAllArr = new Array();
  var ab = begin.split("-");
  var ae = end.split("-");
  var db = new Date();
  db.setUTCFullYear(ab[0], ab[1] - 1, ab[2]);
  var de = new Date();
  de.setUTCFullYear(ae[0], ae[1] - 1, ae[2]);
  var unixDb = db.getTime();
  var unixDe = de.getTime();
  for (var k = unixDb; k <= unixDe; ) {
    dateAllArr.push(new Date(parseInt(k)).formatNoS().toString());
    k = k + 24 * 60 * 60 * 1000;
  }
  return dateAllArr;
}
import {
  Swipe,
  SwipeItem,
  Rate,
  ActionSheet,
  Calendar,
  Icon,
  Toast,
  Image,
  Popup,
  NoticeBar
} from "vant";
export default {
  components: {
    "van-image": Image,
    "van-icon": Icon,
    "van-calendar": Calendar,
    "van-action-sheet": ActionSheet,
    "van-rate": Rate,
    "van-swipe": Swipe,
    "van-swipe-item": SwipeItem,
    "van-notice-bar": NoticeBar,
    "van-popup":Popup
  },
  data() {
    return {
      td_collect_empty,
      td_collect_full,
      apis:{
        types:'/commodity/queryCommodityTypeByCode?code=jqzx',//商品类型
        // tickets: '/commodity/queryComposeCommoditListByCode',//商家商品列表
        tickets: '/commodity/findCommodity',//商家商品列表
        scenic:'/seller/find',//获取景点信息
        addCollect:'/userCollect/addCollect',//取消点赞
        cancelCollect:'/userCollect/cancelMyCollect',//取消点赞
        findPriceCalendarByCommodityId: '/commodity/findPriceCalendarByCommodityId',//价格日历
        queryChildeCommodityById: '/commodity/queryChildeCommodityById',//是否有子商品，有就有门票

      },

      isCollect:0,//是否收藏
      comTick:{tags:'',attachments:[]},
      sellerInfo:{notice:'',attachments:[]},
      typesId:'',
      typeName:'',
      remark:'',
      bizType: 3,//商家固定的业务类型
      colorState: false,
      showHeadBox: false, //是否展示头部盒子
      showBuyDate:false,//购买日期弹窗
      showDatePicker:false,//购买日期弹窗
      current: 0,
      isHasChild:false,
      phone:'400—8196333',//电话
      showTel:false,//客服弹窗
      images: [
        "https://img01.yzcdn.cn/vant/apple-1.jpg",
        "https://img01.yzcdn.cn/vant/apple-2.jpg"
      ],
      dateArr:[],
      // timeArr:[],
      chooseTime:null,
      chooseDate:null,
      commodityIdFinal:null,
      timeDate:null,
      cTimeId:null,
      cTime:null,
    };
  },
  mounted() {
    this.getTypes();
    this.getSeller();
    window.addEventListener("scroll", this.scrollFunc, true);
    document.querySelector('body').setAttribute('style', 'background-color:#fff')
  },
  created(){
  },
  methods: {
    tel(){
      this.showTel = true
    },
    // 点击预定
    Reserve(){
      if(this.isHasChild){
        this.showBuyDate = true;
        this.dateArr = [];
        // this.timeArr = [];
        this.chooseTime = null;
        this.chooseDate = null;
        this.findPriceCalendarByCommodityId()
      }else{
        this.goOrder()
      }
    },
    closeBuyDate(){
        this.showBuyDate = false
    },
    //立即预定
    goOrder(){
      let _t = this;
      if(_t.isHasChild){
        if(_t.dateArr.length && !_t.cDate){
          if(!_t.commodityIdFinal) return Toast('请选择日期');
        }
        /*if(_t.timeArr.length && !_t.cTime){
          if(!_t.commodityIdFinal) return Toast('请选择时间段');
        }*/
      }
      sessionStorage.clear();
      sessionStorage.setItem('LOGIN_STATUS',2);
      sessionStorage.setItem('token',this.$global.token);
      let path = '/ComboDetail';
      this.$router.push({
        path:path,
        query:{
          name:_t.buyTitle,
          commodityId:_t.commodityIdFinal || _t.$route.query.commodityId,
          sellerId:_t.$route.query.sellerId,
          d:_t.cDate ? _t.cDate.replace(/-/g,':') : '',
          t:_t.cTime,
          p:_t.cPrice,
          n:_t.typeName,
          r:_t.comTick.remark,
          typesId:_t.typesId,
          // i: pointUseSwitch,
          // u: creditPointsNum,
          self: _t.sellerInfo.isSelfSupport,
          realName: _t.sellerInfo.isRealName
        }
      })
    },
    // 价格日历
    findPriceCalendarByCommodityId(){
      let me = this;
      me.$get({
        url:me.apis.findPriceCalendarByCommodityId,
        params: {
          commodityId: this.comTick.commodityId,
          token: me.$global.token,
          pageSize: 15,
          // startDate:me.timeDate
        }
      }).then(rsp => {
        if(rsp.data.length){
          let allDateArr = [];
          rsp.data.map((item,i) => {
            let dis = false;
            let tStr = '';
            if(isiOS){
              // var t = new Date(time.replace(/-/g,'/')).getTime();
              tStr = new Date((item.startDate+' '+'23:59:59').replace(/-/g,'/')).getTime()
            }else{
              tStr = new Date(item.startDate+' '+'23:59:59').getTime()
            }
            if(tStr<new Date().getTime()){
              dis = true;
            }
            let obj = {
              date:item.startDate,
              dis:dis,
              commodityId:item.commodityId,
              balancePrice:item.balancePrice,
              commodityName:item.commodityName,
              // endDate:item.endDate,
              priceCalendarId:item.priceCalendarId,
              priorityLevel:item.priorityLevel,
              sellPrice:item.sellPrice,
              sellerType:item.sellerType,
              shopPrice:item.shopPrice,
              startDate:item.startDate,
              typeId:item.typeId,
            };
            allDateArr.push(obj)
          });
          me.dateArr = JSON.parse(JSON.stringify(allDateArr));
          me.showBuyDate = true;
        }else{
          Toast('该商品暂无库存！')
        }
      });
    },

    moreDate(){
      this.showDatePicker = true
    },
    //日期弹窗确认
    confirmDate(date){
      let _t = this;
      _t.dateArr.map((item,index) => {
        let itemDate = '';
        if(isiOS){
          itemDate = new Date(item.date.replace(/-/g,'/')+' 00:00:00').getTime();
        }else{
          itemDate = new Date(item.date+' 00:00:00').getTime();
        }
        if(new Date(date).getTime() === itemDate){
          // _t.chooseDate = index
          _t.dateClick(index,item)
        }
      });
      this.showDatePicker = false
    },
    //点击时间段
    timeClick(index,row){
      let _t = this;
      _t.chooseTime = index;
      _t.cTime = row.timeSlot;
      _t.commodityIdFinal = row.commodityId;
      _t.cPrice = row.sellPrice;
      _t.cTimeId = row.commodityId;
      _t.findPriceCalendarByCommodityId(row,1);

    },
    //点击日期
    dateClick(index,row){
      this.chooseDate = index;
      document.getElementById('date'+index).scrollIntoView();
      let dateStr = isiOS ? row.date.replace(/-/g,'/')+' 00:00:00' : row.date+' 00:00:00';
      this.cPrice = row.sellPrice;
      this.cTimeId = row.commodityId;
      this.selectDate(dateStr);
    },
    selectDate(date){
      let _t = this;
      let d = new Date(date);
      let m = (d.getMonth()+1)<10 ? '0'+(d.getMonth()+1) : (d.getMonth()+1);
      let day = (d.getDate())<10 ? '0'+(d.getDate()) : (d.getDate());
      _t.cDate = d.getFullYear()+'-'+m+'-'+day;
      // _t.queryThirdSystemCode(new Date(date).formatNoS())
      this.timeDate = new Date(date).formatNoS();
    },
    /*//时间段
    queryThirdSystemCode(date){
      let me = this;
      me.chooseTime = null;
      me.cTime = null;
      me.commodityIdFinal = null;
      // me.cPrice = null;
      // me.cTimeId = null;
      me.$get({
        url:me.apis.tickets,
        params: {
          parentId: me.cTimeId,
          commodityTypeId: me.commodityTypeId,
          token: me.$global.token,
          searchTime:date.split(' ')[0],
        }
      }).then(rsp => {//thirdName
        me.timeArr = rsp.data;
        if(!rsp.data.length){
          me.commodityIdFinal = JSON.parse(JSON.stringify(me.cTimeId));
        }else{
          me.timeArr.map((item,index) => {
            item.dis = null;
            let itemDate = null;
            if(isiOS){
              itemDate = new Date(date.replace(/-/g,'/')+ ' '+item.timeSlot.split('-')[1]).getTime();
            }else{
              itemDate = new Date(date+ ' '+item.timeSlot.split('-')[1]).getTime();
            }
            if(itemDate<new Date().getTime()){
              item.dis = true
            }
          })
        }
      });
    },*/
    //渲染价格自定义文案
    formatter(day) {
      let _t = this;
      day.type = 'disabled';
      _t.dateArr.map((item,index) => {
        let itemDate = '',
          dayDate = new Date(day.date).getTime(),
          nowDate = new Date().getTime(),
          cDate = '';
        if(isiOS){
          itemDate = new Date(item.date.replace(/-/g,'/')+' 00:00:00').getTime();
          cDate = _t.cDate ? new Date(_t.cDate.replace(/-/g,'/')+' 00:00:00').getTime() : '';
        }else{
          itemDate = new Date(item.date+' 00:00:00').getTime();
          cDate = _t.cDate ? new Date(_t.cDate+' 00:00:00').getTime() : '';
        }
        if(dayDate === itemDate){
          if(!day.bottomInfo){
            day.bottomInfo = item.sellPrice ? '￥'+item.sellPrice/100 :'￥0';
          }
          if(dayDate>nowDate){
            day.type = '';
          }
          if(cDate === itemDate){
            day.type = 'selected';
          }
        }
      });
      return day;
    },
    queryChildeCommodityById(){
      let me = this;
      me.$get({
        url: me.apis.queryChildeCommodityById,
        params:{
          commodityId: this.comTick.commodityId,
          pageSize: 99,
          pageIndex: 1,
        }
      }).then(rsp => {
        if(rsp.data.length){
          me.isHasChild = true;
        }
      })

    },
    addCollect(){//添加收藏
      const me = this;
      me.$post({
        url: me.apis.addCollect,
        params:{
          token:me.$global.token,
          bizType: me.bizType,
          bizId: me.$route.query.sellerId
        }
      }).then(rsp => {
        me.isCollect = 1;
      })
    },
    cancelCollect(){//取消收藏
      const me = this;
      me.$post({
        url: me.apis.cancelCollect,
        params:{
          token: me.$global.token,
          bizType: me.bizType,
          bizId: me.$route.query.sellerId
        }
      }).then(rsp => {
        me.isCollect = 0;
      });
    },
    //收藏事件响应事件
    collect(){
      this.isCollect == 1 ? this.cancelCollect() : this.addCollect();
    },
    //跳转到景区详情
    goIntro(path,num){
      this.$router.push({
        path:path,
        query:{
          key:this.$route.query.sellerId,
          type:this.bizType,
          orderType:num,
        }
      })
    },
    // 导航
    goMap(){
      // let arr = this.$global.loactions.split(',')
      let isMap = JSON.parse(localStorage.getItem('map')) ? true : false;
      if(!isMap) return Toast('定位失败，无法导航');
      let locations = JSON.parse(localStorage.getItem('map')).position;
      let ipGo=null;
      // ipGo = `https://uri.amap.com/navigation?from=${locations.lng},${locations.lat},起点&to=${this.sellerInfo.longitude},${this.sellerInfo.latitude},${this.sellerInfo.name}&callnative=1`
      ipGo = `https://www.amap.com/dir?from[name]=起点&from[lnglat]=${locations.lng},${locations.lat}&to[name]=${this.sellerInfo.name}&to[lnglat]=${this.sellerInfo.longitude},${this.sellerInfo.latitude}&policy=1&type=car&callnative=1`

      // window.open(ipGo)
      this.$router.push({
        path:'/outWindow',
        query:{
          interlinkage:encodeURI(ipGo)
        }
      })
    },
    getSeller(){
      let me = this;
      me.$get({
        url: me.apis.scenic,
        params:{
          sellerId: me.$route.query.sellerId,
          token: me.$global.token
        }
      }).then(rsp => {
        this.sellerInfo = rsp;
        this.isCollect = me.sellerInfo.isCollect;
      })
    },
    getTickets(){
      let me = this;
      me.$get({
        url:me.apis.tickets,
        params: {
          commodityId: this.$route.query.commodityId,
          token: me.$global.token,
          // sellerId: me.$route.query.sellerId
        }
        /*params: {
          parentId: 0,
          commodityTypeId: me.typesId,
          sellerId: me.$route.query.sellerId
        }*/
      }).then(rsp => {//thirdName=
        this.comTick = rsp;
        let shareUrl = window.location.href.split('#')[1];
        if(JSON.stringify(this.$route.query) === '{}'){
          shareUrl = shareUrl+'?share=1'
        }else{
          shareUrl = shareUrl+'&share=1'
        }
        let pri = this.comTick.sellPrice ? this.comTick.sellPrice/100 : 0
        this.getShareInfo(
          "￥"+pri+this.comTick.name,
          this.$global.visitUrl+'#'+shareUrl,
          this.comTick.cover,
          this.comTick.depiction.replace(/<\/?.+?\/?>/g,''),
        );
        this.queryChildeCommodityById();
        // commodityId
        /*if(rsp.data.length){
          rsp.data.map(item => {
            if(item.commodityId == this.$route.query.commodityId){
              this.comTick = item;
              let shareUrl = window.location.href.split('#')[1];
              if(JSON.stringify(this.$route.query) === '{}'){
                shareUrl = shareUrl+'?share=1'
              }else{
                shareUrl = shareUrl+'&share=1'
              }
              let pri = this.comTick.sellPrice ? this.comTick.sellPrice/100 : 0
              this.getShareInfo(
                "￥"+pri+this.comTick.name,
                this.$global.visitUrl+'#'+shareUrl,
                this.comTick.cover,
                this.comTick.depiction.replace(/<\/?.+?\/?>/g,''),
              );
              this.queryChildeCommodityById();
            }
          })
        }*/
      });
    },
    getTypes(){
      let me = this;
      me.$get({
        url: me.apis.types,
        params:{
          code:'jqzx',
        }
      }).then(rsp => {
        me.typesId = rsp.commodityTypeId;
        me.typeName = rsp.naem;
        this.getTickets();
      })
    },
    // 返回
    revert(num) {
      //返回安卓原生页面或者h5页面
      if(this.$route.query.share == 1){
        this.$router.push('/');
      }else{
        this.$router.go(-1);
      }
    },
    onChange(index) {
      this.current = index;
    },
    scrollFunc() {
      let top =
        document.getElementById("app").scrollTop ||
        document.documentElement.scrollTop;
      if (top > 30) {
        this.colorState = true;
      } else {
        this.colorState = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.line {
  height: 10px;
  background: #eeeeee;
  width: 100%;
}
.p_b {
  padding-bottom: 90px;
}
.telPop{
  width: 350px;
  height: 180px;
  p{
    font-size: 15px;
    font-weight: 500;
    color: #333333;
    text-align: center;
    margin-top: 26px;
  }
  a{
    font-size: 24px;
    display: block;
    text-align: center;
    margin-top: 27px;
    font-weight: bold;
    color: #333333;

  }
  .kefu{
    display: block;
    text-align: center;
    margin: 16px auto 0;
    width: 108px;
    height: 24px;
    color: #FFFFFF;
    line-height: 24px;
    font-size: 12px;
    background: #F96E6E;
    border-radius: 12px;
  }
}
.bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 99;
  width: 100%;
  height: 48px;
  background: #fff;
  box-shadow: 0px -5px 5px 0px rgba(6, 0, 1, 0.14);
  display: flex;
  .kefu {
    display: block;
    height: 48px;
    line-height: 48px;
    padding-left: 26px;
    background: url("./img/kefu.png") no-repeat left center;
    background-size: 21px 22px;
    font-size: 14px;
    color: #141212;
    margin-left: 21px;
  }
  .btn {
    width: 98px;
    line-height: 33px;
    text-align: center;
    height: 33px;
    background: linear-gradient(90deg, #92e460, #3bd367);
    border-radius: 16px;
    color: #fff;
    font-size: 14px;
    margin-left: auto;
    margin-right: 21px;
    margin-top: 9px;
  }
}
.content {
  padding-top: 10px;
  >div{
    width: 355px;
    margin: 0 auto;
    /deep/img{
      max-width: 100%;
    }
  }
  h3 {
    width: 355px;
    margin: 0 auto;
    text-align: center;
    background: url("./img/line.png") no-repeat center;
    background-size: 100% 1px;
    font-size: 18px;
    margin-bottom: 10px;
  }
}
.illustrate {
  padding-top: 10px;
  padding-bottom: 12px;
  > p {
    color: #141212;
    font-size: 10px;
    margin-left: 14px;
  }
  .price {
    display: flex;
    margin-left: 14px;
    span {
      display: block;
    }
    span:nth-child(1) {
      color: #f96937;
      font-size: 14px;
    }
    span:nth-child(2) {
      color: #a2a2a2;
      font-size: 12px;
      margin: 0 10px;
    }
    span:nth-child(3) {
      height: 19px;
      background: linear-gradient(90deg, #fcb04f, #f48863);
      border-radius: 2px;
      color: #ffffff;
      line-height: 19px;
      padding: 0 6px;
      font-size: 10px;
    }
  }
  .taocan {
    width: 355px;
    background: #fffcf3;
    border: 1px solid #f96937;
    border-radius: 10px;
    margin: 11px auto 17px;
    ul {
      margin-top: 17px;
      li {
        margin-bottom: 5px;
        span {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #0d0d0d;
          vertical-align: top;
          margin-left: 10px;
          margin-top: 5px;
        }
        p {
          display: inline-block;
          width: 300px;
          font-size: 14px;
          color: #333333;
          line-height: 20px;
          margin-left: 6px;
        }
      }
    }
    h6 {
      height: 14px;
      font-size: 14px;
      font-weight: bold;
      font-style: italic;
      color: #f96937;
      line-height: 35px;
      text-align: center;
    }
  }
}
.info {
  padding-top: 11px;
  padding-bottom: 13px;
  .notice {
    width: 355px;
    margin: 9px auto 0;
  }
  .notice-swipe {
    height: 40px;
    line-height: 40px;
  }
  .nav {
    width: 355px;
    height: 55px;
    background: url("./img/nav.png") no-repeat center;
    background-size: 100% 100%;
    margin: 15px auto 0;
    display: flex;
    .navto {
      width: 38px;
      height: 20px;
      background: #25dac7;
      border-radius: 10px;
      font-size: 12px;
      color: #ffffff;
      text-align: center;
      margin-left: auto;
      line-height: 20px;
      margin-top: 23px;
      margin-right: 22px;
    }
    .address {
      background: url("./img/address.png") no-repeat left center;
      background-size: 13px 14px;
      width: 250px;
      margin-left: 16px;
      padding-left: 20px;
      margin-top: 9px;
      h6 {
        font-size: 15px;
      }
      p {
        font-size: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  h1 {
    font-size: 21px;
    color: #141212;
    margin-left: 15px;
  }
  .tag {
    display: flex;
    margin-left: 15px;
    margin-top: 6px;
    li {
      padding: 0 4px;
      height: 16px;
      background: #ffe4c1;
      border-radius: 1px;
      font-size: 10px;
      color: #f96937;
      line-height: 16px;
      margin-right: 10px;
    }
  }
}
.td_head_nav {
  width: 100%;
  padding: 0 20px;
  height: 46px;
  position: fixed;
  top: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 222;
}
.td_head_nav img:nth-child(1) {
  width: 18px;
  height: 18px;
  z-index: 222;
  margin-right: 6px;
}
.td_head_nav img:nth-child(2) {
  width: 21px;
  height: 5px;
  z-index: 222;
}
.header {
  width: 100%;
  height: 225px;
  position: relative;
  /*background: red;*/
}
.van-swipe-item {
  width: 100%;
  height: 225px;
  img {
    width: 100%;
    height: 100%;
  }
}
.custom-indicator {
  position: absolute;
  right: 50%;
  width: 75px;
  margin-right: -38px;
  bottom: 15px;
  padding: 2px 5px;
  font-size: 12px;
  border-radius: 15px;
  text-align: center;
  color: #fff;
  background-image: linear-gradient(to right, #92e460, #3bd367);
}
.t_buy_date{
    .time_out{
      background:rgba(247,247,247,1);
      border-radius:3px;
      padding: 15px 10px;
      box-sizing: border-box;
      overflow-y: hidden;
      /*margin-bottom: 15px;*/
      .over_out{
        white-space: nowrap;
        overflow-x: auto;
        box-sizing: border-box;
        overflow-y: hidden;
        .time_item{
          .tag{
            width: 18px;
            height: 18px;
            bottom: 0;
            right: 0;
            display: none;
          }
          margin-right: 9px;
          display: inline-block;
          height:26px;
          background:#ffffff;
          border:1px solid rgba(177,177,177,1);
          border-radius:3px;
          line-height: 26px;
          padding: 0 13px;
          color: #141212;
          font-size: 10px;
          position: relative;
          box-sizing: border-box;
          &.dis{
            background:rgba(247,247,247,1);
            border:1px solid rgba(177,177,177,1);
            color: #AEAEAE;
          }
          &.choose{
            background : #3FD467;
            border:1px solid #3FD467;
            color: #ffffff;
            .tag{
              display: block;
            }
          }
        }
      }
    }
    .date_out{
      .date_more{
        .date_more_icon{
          width: 6px;
        }
        width: 44px;
        background:rgba(255,255,255,1);
        border:1px solid rgba(177,177,177,1);
        border-radius:3px;
        padding: 4px;
        box-sizing: border-box;
        font-size:10px;

        font-weight:500;
        color:rgba(20,18,18,1);
        line-height:17px;
        right: 9px;
        top: 15px;
        height: 50px;
      }
      .over_out{
        overflow-x: auto;
        overflow-y: hidden;
        width: 100%;
      }
      .date_item_out{
        white-space: nowrap;
        .date_item{
          width:80px;
          background:white;
          border:1px solid rgba(177,177,177,1);
          border-radius:3px;
          box-sizing: border-box;
          margin-right: 9px;
          padding: 9px 9px 9px 10px;
          color: #141212;
          position: relative;
          display: inline-block;
          .price{
            color: #FF3E6E;
          }
          .tag{
            width: 18px;
            height: 18px;
            bottom: 0;
            right: 0;
            display: none;
          }
          &.dis{
            background:rgba(247,247,247,1);
            color: #AEAEAE;
            .price{
              color: #AEAEAE;
            }
          }
          &.choose{
            background:#3FD467;
            border-color: #3FD467;
            color: white;
            .tag{
              display: block;
            }
            .price{
              color: #ffffff;
            }
          }
          .d_f{
            margin-bottom: 4px;
          }
        }
      }
      font-size:10px;
      padding: 15px 57px 15px 10px;
      background:rgba(247,247,247,1);
      border-radius:3px;
      position: relative;
    }
    .tip{
      font-size:14px;

      font-weight:500;
      color:rgba(20,18,18,1);
      margin-bottom: 8px;
    }
    .red_tip{
      height:28px;
      background:rgba(254,246,240,1);
      border-radius:5px;
      line-height: 28px;
      margin-bottom: 9px;
      padding: 0 12px;
      font-size:12px;

      font-weight:500;
      color:rgba(255,74,74,1);
    }
    .head{
      .close{
        width: 11px;
      }
      font-size:16px;

      font-weight:bold;
      color:rgba(51,51,51,1);
      margin-bottom: 9px;
    }
    padding: 18px 20px 63px 20px;
    bottom: 0;
    left: 0;
    background-color: white;
    width: 100%;
    box-sizing: border-box;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
  }
  .date_btn{
    height:46px;
    line-height: 46px;
    text-align: center;
    color: white;
    font-size: 16px;
    background:rgba(63,212,103,1);
    box-shadow:0px 2px 4px 0px rgba(0, 0, 0, 0.13);
    width: 100%;
    bottom: 0;
    left: 0;
  }
</style>

